<template>
  <b-row>
    <b-col md="2">
      <b-form-group
        label="Ticket ID"
      >
        <b-form-input
          v-model="ticketId"
          type="text"
          placeholder="Search Ticket ID"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Invoice Number"
      >
        <b-form-input
          v-model="invoiceNumber"
          type="text"
          placeholder="Search Invoice Number"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Company Group"
      >
        <custom-v-select
          v-model="selectedCompanyGroups"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="companyGroupOptions"
          label="text"
          placeholder="Select Company Groups"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Supplier"
      >
        <custom-v-select
          v-model="selectedSuppliers"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="supplierOptions"
          label="text"
          placeholder="Select Suppliers"
          multiple
          :close-on-select="false"
          :deselect-from-dropdown="true"
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Created On Range"
      >
        <flat-picker
          v-model="dateRange"
          placeholder="Select Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Closed On Range"
      >
        <flat-picker
          v-model="closedOnDateRange"
          placeholder="Select Date Range"
          :config="{ mode: 'range'}"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <b-form-group
        label="Status"
      >
        <custom-v-select
          v-model="selectedStatusOption"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="statusOptions"
          label="text"
          placeholder="Select Option"
          multiple
          @input="filtersChangeHandler"
        />
      </b-form-group>
    </b-col>
    <b-col md="2">
      <DiscrepancyTypeFilter
        v-model="discrepancyType"
        :options="discrepancies"
        @input="filtersChangeHandler"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  VBTooltip, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import FlatPicker from '@/components/UI/FlatPicker.vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'
import DiscrepancyTypeFilter from '@/components/Tickets/DiscrepancyTypeFilter.vue'
import { discrepancies } from '@/components/Tickets/TicketDiscrepancy/discrepancy-types'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    FlatPicker,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    CustomVSelect,
    DiscrepancyTypeFilter,
  },
  data() {
    return {
      ticketId: null,
      invoiceNumber: null,
      companyGroupOptions: [],
      selectedCompanyGroups: [],
      supplierOptions: [],
      selectedSuppliers: [],
      dateRange: null,
      closedOnDateRange: null,
      statusOptions: [{ text: 'Open', value: 'Open' }, { text: 'In Progress', value: 'In Progress' }, { text: 'Closed', value: 'Closed' }],
      selectedStatusOption: null,
      discrepancyType: [],
      discrepancies,
    }
  },
  watch: {
    dateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
    closedOnDateRange(newValue) {
      if (newValue) {
        const [fromDate, toDate] = newValue.split(' to ')
        if (fromDate && toDate) {
          this.filtersChangeHandler()
        }
      } else if (newValue === '') {
        this.filtersChangeHandler()
      }
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/company_group_options')
        .then(res => {
          this.companyGroupOptions = res.data.data
        })

      axios.get('/supplier_options')
        .then(res => {
          this.supplierOptions = res.data.data
        })
    },
    filtersChangeHandler() {
      const filtersdata = {}
      if (this.ticketId) {
        filtersdata.ticket_id = this.ticketId
      }
      if (this.invoiceNumber) {
        filtersdata.invoice_no = this.invoiceNumber
      }
      if (this.selectedCompanyGroups.length > 0) {
        filtersdata.company_groups = this.selectedCompanyGroups.map(item => item.id)
      }
      if (this.selectedSuppliers.length > 0) {
        filtersdata.suppliers = this.selectedSuppliers.map(item => item.id)
      }
      if (this.dateRange) {
        const [dateFrom, dateTo] = this.dateRange.split(' to ')
        if (dateFrom && dateTo) {
          filtersdata.created_date_from = dateFrom
          filtersdata.created_date_to = dateTo
        }
      }
      if (this.closedOnDateRange) {
        const [closedOnDateFrom, closedOnDateTo] = this.closedOnDateRange.split(' to ')
        if (closedOnDateFrom && closedOnDateTo) {
          filtersdata.closed_on_from = closedOnDateFrom
          filtersdata.closed_on_to = closedOnDateTo
        }
      }
      if (this.selectedStatusOption) {
        filtersdata.status = this.selectedStatusOption.map(opt => opt.value)
      }

      if (this.discrepancyType.length > 0) {
        filtersdata.discrepancy_types = this.discrepancyType
      }
      this.$emit('filtersUpdated', filtersdata)
    },
  },

}
</script>
